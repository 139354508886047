/**
 * Host Hunt Print Page
 * 
 * Allow the host to see all of the hunt information, including all of the
 * questions and their answers.
 */
import React from 'react'
import { navigate } from 'gatsby'
import { isHostLoggedIn } from '../../utils/host-auth'
import HostNavPanel from '../../components/host/nav-panel'
import API from '../../utils/api'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import { b64DecodeUnicode } from '../../utils/helpers'

dayjs.extend(LocalizedFormat)

export default class HostPrintPage extends React.Component {
  constructor(props) {
    super(props)
    this.updateSelf = this.updateSelf.bind(this)
    this.state = {
      hunt: null,
    }
  }

  componentDidMount() {
    this.updateSelf()
  }

  updateSelf() {
    isHostLoggedIn().then(loggedIn => {
      if (loggedIn) {
        let hunt_id = this.props.location.hash.substring(1)
        API.get(`/host/hunt/${hunt_id}/`, {
          headers: {
            Authorization: `JWT ${localStorage.getItem(`token`)}`,
          },
        })
          .then(res => {
            let json = res.data
            this.setState({ hunt: json })
          })
          .catch(function(error) {
            console.log(error)
            navigate(`/host/`)
            return null
          })
      } else {
        navigate(`/host/login/`)
      }
    })
  }

  render() {
    const { hunt } = this.state
    return (
      <div className={`page-wrap print-view`}>
        {hunt && (
          <div className="py-5 container">
            <div className="card px-3 py-4">
              <h1>{hunt.title}</h1>
              <h3>{hunt.location}</h3>
              <div>
                <strong>Hunt Type: </strong>
                {hunt.hunt_type == 1 ? `Grab & Go` : `Default`}
              </div>
              <div>
                <strong>Description: </strong> <div dangerouslySetInnerHTML={{ __html: hunt.description }} />
              </div>
              <div>
                <strong>Start: </strong> {dayjs(hunt.start).format(`L LT`)}
              </div>
              <div>
                <strong>Duration: </strong> {hunt.duration}
              </div>
              <div>
                <strong>Stop Word:</strong> {hunt.stop_word}
              </div>
              <div>
                <strong>Client:</strong> {hunt.client}
              </div>
              <div>
                <strong>Play Sounds:</strong> {hunt.play_sounds ? `yes` : `no`}
              </div>
              <div>
                <strong>Hints Enabled:</strong> {hunt.enable_hints ? `yes` : `no`}
              </div>
              <div>
                <strong>Show Answers Enabled:</strong> {hunt.enable_answers ? `yes` : `no`}
              </div>
              <div>
                <strong>Timer Enabled:</strong> {hunt.enable_timer ? `yes` : `no`}
              </div>
              <div>
                <strong>Photos Enabled:</strong> {hunt.enable_photos ? `yes` : `no`}
              </div>
              <div>
                <strong>Photo Gallery Enabled:</strong> {hunt.enable_gallery ? `yes` : `no`}
              </div>
              <div>
                <strong>Rules:</strong> <div dangerouslySetInnerHTML={{ __html: hunt.rules }} />
              </div>

              <h3>Questions</h3>
              <ol>
                {hunt.questions.map((question, index) => (
                  <li className="question mb-4" key={`question-${index}`}>
                    <strong>Location: </strong>
                    {question.location}
                    <br />
                    {/* <strong>Directions From Start: </strong><div dangerouslySetInnerHTML={ {__html:question.directions_from_start }} /> */}
                    <strong>Directions Clockwise: </strong><div dangerouslySetInnerHTML={{ __html:question.directions_clockwise }} />
                    {/* <strong>Directions Counterclockwise: </strong><div dangerouslySetInnerHTML={{ __html:question.directions_counterclockwise }} /> */}
                    <div>
                      <strong>Question: </strong>
                      <span className="print-item" dangerouslySetInnerHTML={{ __html: question.question }} />
                    </div>
                    <div>
                      <strong>Hint: </strong>
                      <span className="print-item" dangerouslySetInnerHTML={{ __html: question.hint }} />
                    </div>
                    <strong>Answers: </strong>
                    {b64DecodeUnicode(question.answers_aes_crypt)}
                    <br />
                    <strong>Point Value:</strong> {question.answer_value} Full / {question.hint_value} Partial
                  </li>
                ))}

                {hunt.checklists.length > 0 && (
                  <>
                    <h3>Checklists</h3>
                    <ol>
                      {hunt.checklists.map((checklist, index) => (
                        <li className="checklist mb-4" key={`checklist-${index}`}>
                          <strong>Title: </strong>
                          {checklist.title}
                          <br />
                          <strong>Checklist Type: </strong>
                          {checklist.type}
                          <br />
                          <strong>Display Title: </strong>
                          {checklist.display_title}
                          <br />
                          <strong>Maxiumum Score: </strong>
                          {checklist.maximum_score}
                          <br />
                          <div>
                            <strong>Description: </strong>
                            <span className="print-item" dangerouslySetInnerHTML={{ __html: checklist.description }} />
                          </div>
                          <strong>Checklist Questions:</strong>
                          <ol>
                            {checklist.questions.map((c_question, index) => (
                              <li key={`checklist-question-${c_question.id}`}>
                                <strong>Title: </strong>
                                {c_question.title}
                                <br />
                                <strong>Description: </strong>
                                {c_question.description}
                                <br />
                                <strong>Point Value: </strong>
                                {c_question.answer_value}
                              </li>
                            ))}
                          </ol>
                        </li>
                      ))}
                    </ol>
                  </>
                )}
              </ol>
            </div>
          </div>
        )}
      </div>
    )
  }
}
