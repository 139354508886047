/**
 * Host Nav Panel Component
 * 
 * Host navigation panel, to go between host dashboard pages.
 */
import React from 'react'
import { navigate } from 'gatsby'
import dayjs from 'dayjs'
import HostContext from '../../utils/host-context'

class HostNavPanel extends React.Component {
  render() {
    return (
      <HostContext.Consumer>
        {({ active_hunts }) => (
          <ul className={`list-unstyled px-5 list-menu host-list-menu`}>
            <li>
              <button className={`btn btn-link px-0`} onClick={() => navigate(`/host/`)}>
                Your Hunts
              </button>
            </li>
            {/* <li>
              <h5 className="text-uppercase mb-0 btn-link">Choose a Hunt</h5>
              <ul className="list-unstyled pl-3">
                {active_hunts.map(hunt => (
                  <li key={hunt.id}>
                    <button
                      className={'btn btn-link px-0 btn-host-hunt'}
                      onClick={() => navigate(`/host/hunt/#${hunt.id}`)}>
                      {hunt.display_title ? hunt.display_title : hunt.title} <br />
                      <small>
                        {hunt.event_date
                          ? dayjs(hunt.event_date).format('MM/DD/YYYY')
                          : dayjs(hunt.start).format('MM/DD/YYYY')}
                      </small>
                      <br />
                      <small>{hunt.location}</small>
                      <br />
                      <small>{hunt.client}</small>
                    </button>
                  </li>
                ))}
              </ul>
            </li> */}
            <li>
              <button className={`btn btn-link px-0`} onClick={() => navigate(`/host/logout/`)}>
                Logout
              </button>
            </li>
          </ul>
        )}
      </HostContext.Consumer>
    )
  }
}

export default HostNavPanel
